.display-panel {
	margin-top: 15px;
}

.new-spotify-url {
	margin-top: 15px;	
}

.new-spotify-url a {
	color: white;	
}

.display-panel .dots span {
	animation: bounce 1.5s infinite;
	opacity: 0;
}

.display-panel .dots span:nth-child(1) {
	animation-delay: 0s;
}

.display-panel .dots span:nth-child(2) {
	animation-delay: 0.3s;
}

.display-panel .dots span:nth-child(3) {
	animation-delay: 0.6s;
}

@keyframes bounce {
	0%,
	100% {
		opacity: 0;
		transform: translateY(0);
	}
	50% {
		opacity: 1;
		transform: translateY(-5px);
	}
}
