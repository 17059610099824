sup {
	font-size: 12px;
}

.playlist-form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: #fff;
}

.playlist-form input {
	width: 70%;
	padding: 10px;
	margin: 15px 0;
	font-family: inherit;
	font-size: 1em;
	border-radius: 5px;
	border: 1px solid #def457;
	background-color: #2e3047;
	color: #fff;
}

.playlist-form button {
	background-color: #2e3047;
	color: white;
	border: none;
	padding: 10px 20px;
	font-size: 1em;
	border-radius: 5px;
	cursor: pointer;
	margin: 15px 0;
	border: 1px solid #def457;
}

.playlist-form button:hover {
	background-color: #def457;
	color: black;
}

.playlist-form button:disabled {
	background-color: #7a7a7a;
	cursor: not-allowed;
}

.disabled-button {
	background-color: #7a7a7a;
	color: #2e2e2e;
	cursor: not-allowed;
}

.info-icon {
	margin-left: 25px;
	color: #def457;
	cursor: pointer;
	margin-bottom: 5px;
	height: 20px;
}

/* .info-icon {
	display: inline-block;
	margin-left: 12px;
	width: 20px;
	height: 20px;
	background-color: #def457;
	color: #201f51;
	border-radius: 50%;
	text-align: center;
	line-height: 20px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 12px;
} */

/* .playlist-form-title {
	display: flex;
} */
