.app {
	max-width: 80%;
	margin: 0 auto;
	padding: 20px;
	background-color: #f5f5f5;
}

@media (max-width: 768px) {
	.app {
		max-width: 100%;
		padding: 10px;
	}
}

body {
	font-family: 'JetBrains Mono', monospace;
	background-color: #201f51;
	color: #fff;
	margin: 0;
	padding: 0;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	min-height: 100vh;
}

.app {
	/* max-width: 1200px; */
	padding: 20px;
	background-color: #201f51;
}

.main-container {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.left-panel,
.right-panel {
	width: 50%;
	margin: 0px 15px 0px 15px;
	overflow-wrap: break-word;
}

.left-panel {
	display: flex;
	flex-direction: column;
}

.right-panel {
	display: flex;
	flex-direction: column;
}

.line {
	height: 2px;
	background-color: #fff !important;
	margin: 10px 0;
	width: fit-content;
}

h1 {
	font-size: 32px;
	margin: 0;
}

h2 {
	color: #f4ac57;
	font-size: 32px;
}

h3 {
	color: #def457;
	font-size: 20px;
	margin: 0;
}

a {
	color: #def457;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

ul {
	list-style: none;
	padding-left: 0;
}

li {
	font-size: 12px;
	line-height: 1.5;
	color: #fff;
	margin-bottom: 10px;
}

.invalid-tracks {
	color: #def457;
	margin-top: 20px;
}

.additional-info-line {
	visibility: hidden;
}

.footer {
	margin-top: 30px;
	margin-left: 15px;
	font-size: 12px;
}

@media (max-width: 768px) {
	.main-container {
		flex-direction: column;
	}

	.left-panel,
	.right-panel {
		width: 100%;
	}

	.additional-info-line {
		visibility: visible;
	}
}
